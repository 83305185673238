import { RouteComponentProps } from "@reach/router";
import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import Button from "../Components/Button";
import EventRundown from "../Components/EventRundown";
import Layout, { year } from "../Components/Layout";
import Printicon from "../icons/PrintIcon";
interface Props extends RouteComponentProps {}

const SchedulePage = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Layout selected="/schedule" className="pt-24 flex h-full pb-12">
      <Helmet title={t("Schedule")} />
      <div className="flex flex-wrap container mx-auto font-sans">
        <h1 className="w-full font-sans font-bold text-4xl text-red-600">
          {t("Our Schedules")}
        </h1>

        {/* <div className="w-full flex-wrap items-start flex justify-end pt-5 md:py-10">
          <Button
            size="md"
            className="flex items-center font-sans md:text-xl font-semibold bg-white"
            color="secondary"
            outline
            onClick={() => {
              window.open(
                process.env.REACT_APP_BASE_URL + "/public/events/download",
                "_blank"
              );
            }}
          >
            <Printicon className="mr-2 w-6 h-6" />
            {t("Download Schedule")}
          </Button>
        </div> */}

        <div className="mt-10 w-full">
          <EventRundown />
        </div>
      </div>
    </Layout>
  );
};

export default SchedulePage;
