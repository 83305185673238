import { Link } from "@reach/router";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useWindowScroll } from "react-use";
import CloseIcon from "../icons/CloseIcon";
// import FacebookIcon from "../icons/FacebookIcon";
// import InstagramIcon from "../icons/InstagramIcon";
import MenuIcon from "../icons/MenuIcon";
import socials from "../stores/data/socials";
import AuthButton from "./AuthButton";
import LanguageButton from "./LanguageButton";
const menus = [
  { label: "Home", url: "/" },
  { label: "About", url: "/about" },
  { label: "Schedule", url: "/schedule" },
  { label: "Partners", url: "/partners" },
  { label: "Speakers", url: "/speakers" },
  // { label: "Price", url: "/price" },
];

const footerMenus = [
  { label: "Home", url: "/" },
  { label: "About", url: "/about" },
  { label: "Schedule", url: "/schedule" },
  { label: "Partners", url: "/partners" },
  { label: "Speakers", url: "/speakers" },
  // { label: "Price", url: "/price" },
  { label: "Contact", url: "/contact" },
];

export const year = new Date().getFullYear();
const Layout = ({
  children,
  selected = "/",
  transparent = false,
  className = "",
  lightAfter = null,
}) => {
  const [isTransparent, setIsTransparent] = useState(transparent);
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();
  const { y } = useWindowScroll();

  useEffect(() => {
    if (transparent) {
      if (lightAfter?.current) {
        if (y > lightAfter?.current?.offsetHeight) {
          setIsTransparent(false);
        } else {
          setIsTransparent(true);
        }
      }
    }
  }, [y, lightAfter, transparent]);

  return (
    <div className="min-h-screen flex flex-col">
      <Helmet titleTemplate="%s - IBD" defaultTitle="Home - IBD"></Helmet>
      <header
        id="main-header"
        className={clsx(
          "fixed top-0 w-full z-30 border-b border-transparent transition-all duration-200",
          {
            "bg-black bg-opacity-40": isTransparent,
            "bg-white": !isTransparent,
            "border-gray-300 shadow-md": !isTransparent && y > 0,
          }
        )}>
        <div className="container mx-auto flex items-center py-4">
          <div>
            <Link to="/">
              <h1 className="text-white text-xl flex items-center font-semibold">
                {isTransparent ? (
                  <>
                    <img
                      src="/images/logo-pandi.png"
                      alt={t("Home")}
                      className="h-6 md:h-10"
                    />
                    <img
                      src="/images/ibd/logo_ibd_putih.png"
                      alt={t("Home")}
                      className="ml-3 h-6 md:h-10 bg-transparent"
                    />
                  </>
                ) : (
                  <>
                    <img
                      className="h-6 md:h-10"
                      src="/images/logo-pandi-color.png"
                      alt="Express Freedom"
                    />
                    <img
                      src="/images/ibd/logo_ibd.png"
                      alt={t("Home")}
                      className="ml-3 h-6 md:h-10 bg-transparent"
                    />
                  </>
                )}
              </h1>
            </Link>
          </div>
          <div className="flex-1 flex justify-end md:hidden">
            <button
              className="appearance-none block"
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}>
              <MenuIcon
                className={clsx("w-8 h-8", {
                  "text-white": isTransparent,
                  "text-gray-800": !isTransparent,
                })}
              />
            </button>
          </div>
          <div
            className={clsx("md:hidden", {
              "w-screen top-0 h-screen fixed bg-black bg-opacity-50": menuOpen,
              "w-0 h-0": !menuOpen,
            })}
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}
          />
          <nav
            className={clsx(
              "menus fixed top-0 md:static transform duration-200 flex-1 font-sans uppercase",
              "md:bg-transparent w-screen md:w-auto h-full md:h-auto md:p-0",
              { "bg-gray-700": isTransparent, "bg-white": !isTransparent },
              {
                "-left-16 pl-16": menuOpen,
                "-left-full": !menuOpen,
              }
            )}>
            <div className="flex md:hidden">
              <h1 className="text-xl font-semibold px-6 py-4 text-white flex-1">
                &nbsp;
              </h1>
              <button
                className={clsx("px-3", {
                  "text-white": isTransparent,
                  "text-gray-800": !isTransparent,
                })}
                onClick={() => {
                  setMenuOpen(!menuOpen);
                }}>
                <CloseIcon className="w-8 h-8" />
              </button>
            </div>

            <ul className="flex justify-end flex-col md:flex-row capitalize">
              {menus.map((item, index) => (
                <li
                  key={index}
                  className={clsx("md:mx-1", {
                    "font-bold bg-black bg-opacity-40 md:rounded-full":
                      item.url === selected,
                  })}>
                  <Link
                    to={item.url}
                    className={clsx(
                      "block px-8 py-3 md:px-3 md:py-1",
                      { "text-gray-800": !isTransparent },
                      { "text-white": isTransparent }
                    )}
                    style={{
                      color: item.url === selected ? "#fff" : "",
                    }}>
                    {t(item.label)}
                  </Link>
                </li>
              ))}

              <li
                className={clsx("md:mx-1", {
                  "font-bold bg-black bg-opacity-40 md:rounded-full":
                    "/login" === selected,
                })}>
                <AuthButton transparent={isTransparent} selected={selected} />
              </li>

              <li className="md:mx-1 h-8 p-1">
                <LanguageButton isTransparent={isTransparent} />
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <div className={clsx("flex-1", className)}>{children}</div>
      <footer id="main-footer" className="bg-gray-200">
        <div className="container mx-auto pt-10">
          <div className="flex flex-col md:flex-row items-stretch -mx-4">
            <div className="md:w-3/12 px-4 mb-4 md:mb-0 flex items-center">
              <div className="space-y-3">
                <img
                  src="/images/logo-pandi-color.png"
                  alt="Express Freedom"
                  className="h-12 md:h-16"
                />
                <img
                  src="/images/ibd/logo_ibd.png"
                  alt="res"
                  className="h-10 md:h-14"
                />
              </div>
            </div>
            <div className="md:w-3/12 px-4 mb-4 md:mb-0">
              <ul className="font-sans">
                {footerMenus.map((item, index) => (
                  <li key={index} className="mb-2">
                    <Link
                      className="font-semibold hover:underline"
                      to={item.url}>
                      {t(item.label)}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="md:w-3/12 px-4 mb-4 md:mb-0">
              {/* <h2 className="text-xl font-sans font-bold mb-2">
                {t("Payment Method")}
              </h2>
              <figure>
                <img src="/images/bca.png" alt="BCA" className="max-h-10" />
              </figure> */}
            </div>
            <div className="md:w-3/12 px-4 mb-4 md:mb-0">
              <h2 className="text-xl font-sans font-bold mb-2">
                {t("Follow Us")}
              </h2>
              <div className="flex -mx-3 pt-1">
                {socials.map((item, index) => (
                  <div key={index} className="mx-3">
                    <a href={item.url} target="_blank" rel="noreferrer">
                      {item.icon}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="py-4 text-center font-sans">
            &copy; {year} PANDI - All rights reserved
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
