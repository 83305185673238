import React from "react";
import { useTranslation } from "react-i18next";
import SwiperCore, { Autoplay, Navigation } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import useSWR from "swr";
import { useWithLang } from "../i18n";
import ChevronLeft from "../icons/ChevronLeft";
import ChevronRight from "../icons/ChevronRight";
import { ApiResponse, getBaseURL } from "../utils/api";

SwiperCore.use([Navigation, Autoplay]);

export interface Sponsor {
  id: number;
  name: string;
  image: string;
  featured: number;
  status: number;
}

const EventSponsors = () => {
  const { t } = useTranslation();
  const { f } = useWithLang();

  const { data } = useSWR<ApiResponse<Sponsor[]>>(f("/public/sponsors"), {
    initialData: null,
  });

  return (
    <div className="bg-white">
      <div className="container mx-auto py-20">
        <div className="flex items-center mb-8">
          <div className="flex flex-1 items-center">
            <div className="mr-3">
              <img
                src="/images/pandi.png"
                className="w-14 md:w-auto"
                alt="pandi id logo"
              />
            </div>
            <h1 className="text-3xl font-sans font-semibold flex-1">
              {t("Event Partners")}
            </h1>
          </div>
          {data?.data?.length > 4 && (
            <div>
              <button className="transform duration-150 appearance-none border border-gray-500 rounded-md mx-2 sponsor-prev-slide hover:bg-red-600 hover:border-red-600 hover:text-white">
                <ChevronLeft />
              </button>
              <button className="transform duration-150 appearance-none border border-gray-500 rounded-md mx-2 sponsor-next-slide hover:bg-red-600 hover:border-red-600 hover:text-white">
                <ChevronRight />
              </button>
            </div>
          )}
        </div>
        <div>
          <Swiper
            spaceBetween={20}
            slidesPerView={3}
            loop={data?.data?.length > 4}
            autoplay={{
              delay: 2000,
            }}
            navigation={{
              prevEl: ".sponsor-next-slide",
              nextEl: ".sponsor-prev-slide",
            }}
            breakpoints={{
              768: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}>
            {data === null ? (
              <>
                {[...new Array(4)].map((_, index) => (
                  <SwiperSlide key={index}>
                    <div className="bg-gray-300 h-20 rounded-xl flex justify-center items-center"></div>
                  </SwiperSlide>
                ))}
              </>
            ) : (
              <>
                {data.data.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="bg-gray-300 h-20 rounded-xl flex justify-center items-center p-1">
                      <img
                        src={getBaseURL(item.image)}
                        alt={item.name}
                        className="max-h-full"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </>
            )}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default EventSponsors;
