import React from "react";
import FacebookIcon from "../../icons/FacebookIcon";
import InstagramIcon from "../../icons/InstagramIcon";
import TwitterIcon from "../../icons/TwitterIcon";

const socials = [
  {
    label: "Instagram",
    url: "https://instagram.com/pandi_id",
    icon: (
      <InstagramIcon className="text-gray-800 w-8 transition-all duration-150 hover:text-red-600" />
    ),
  },
  {
    label: "Facebook",
    url: "https://www.facebook.com/pandi.id",
    icon: (
      <FacebookIcon className="text-gray-800 w-8 transition-all duration-150 hover:text-red-600" />
    ),
  },
  {
    label: "Twitter",
    url: "https://twitter.com/pandi_id",
    icon: (
      <TwitterIcon className="text-gray-800 w-8 transition-all duration-150 hover:text-red-600" />
    ),
  },
];

export default socials;
