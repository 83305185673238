import { RouteComponentProps } from "@reach/router";
import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import BannerSponsorship from "../Components/BannerSponsorship";
import { Sponsor } from "../Components/EventSponsors";
import Layout from "../Components/Layout";
import { useWithLang } from "../i18n";
import { ApiResponse, getBaseURL } from "../utils/api";
interface Props extends RouteComponentProps {}

const SponsorPage = (props: Props) => {
  const { t } = useTranslation();
  const { f } = useWithLang();
  const { data } = useSWR<ApiResponse<Sponsor[]>>(f("/public/sponsors"), {
    initialData: null,
  });

  return (
    <Layout selected="/partners" className="pt-24 h-full pb-12">
      <Helmet title={t("Partners")} />
      <BannerSponsorship />
      {data?.data.length === 0 ? (
        <></>
      ) : (
        <div className="flex flex-col flex-wrap container mx-auto font-sans">
          <h1 className="font-sans font-bold text-4xl text-red-600 w-full">
            {t("Partners")}
          </h1>

          <div className="mt-10 w-full">
            <div className="flex flex-wrap -m-2 mb-8">
              {data?.data?.map((item, index) => (
                <div className="w-6/12 md:w-3/12 p-2" key={index}>
                  <div className="bg-gray-300 h-16 md:h-20 rounded-xl flex justify-center items-center p-1">
                    <img
                      src={getBaseURL(item.image)}
                      className="max-h-full"
                      alt={item.name}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default SponsorPage;
