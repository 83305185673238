import React from "react";
import { useTranslation } from "react-i18next";
import SwiperCore, { Autoplay, Pagination } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import useSWR from "swr";
import { useWithLang } from "../i18n";
import { ApiResponse, getBaseURL } from "../utils/api";
import { Testimonial } from "../utils/types";

SwiperCore.use([Pagination, Autoplay]);

const Testimonials = () => {
  const { t } = useTranslation();
  const { f } = useWithLang();
  const { data } = useSWR<ApiResponse<Testimonial[]>>(
    f("/public/testimonial"),
    {
      initialData: null,
    }
  );
  return (
    <div className="bg-red-600">
      <div className="container mx-auto py-10 text-white">
        <div className="flex flex-wrap justify-center">
          <div className="flex items-center flex-1">
            <div className="mr-3">
              <img
                src="/images/pandi-light.png"
                className="w-14 md:w-auto"
                alt="pandi-light"
              />
            </div>
            <h1 className="font-sans text-xl md:text-3xl font-semibold flex-1">
              {t("Testimonials")} /{" "}
              <span className="font-sans font-normal italic">
                {t("See What People Say About Us")}
              </span>
            </h1>
          </div>

          <div className="slide-paginate w-full md:w-auto flex items-center justify-end my-4 md:my-0"></div>
        </div>
        <div className="-mx-4 mt-4 mb-10">
          <Swiper
            loop
            speed={1000}
            autoplay={{
              delay: 2000,
            }}
            pagination={{
              clickable: true,
              bulletClass:
                "block bg-transparent w-3 h-3 rounded-full border border-white cursor-pointer ml-1",
              bulletActiveClass: "bg-white",
              el: ".slide-paginate",
            }}
          >
            {data?.data?.length > 0 &&
              data.data.map((item, index) => (
                <SwiperSlide key={index} className="flex">
                  <div className="pr-4 md:text-lg font-sans px-4 flex-1">
                    <h2 className="text-2xl font-bold mb-2">{item.name}</h2>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.testimony }}
                    ></div>
                  </div>
                  <div className="mr-4">
                    <div className="bg-gray-200 w-28 h-28 rounded-full overflow-hidden flex justify-center items-center">
                      <img src={getBaseURL(item.image)} alt={item.name} />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
