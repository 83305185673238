import { Link, RouteComponentProps } from "@reach/router";
import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import Layout from "../Components/Layout";
import { useWithLang } from "../i18n";
import { ApiResponse } from "../utils/api";
import { splitAndJoin } from "../utils/splitName";
import { RundownEvent } from "../utils/types";
interface Props extends RouteComponentProps {}

const PastEventPage = (props: Props) => {
  const { t } = useTranslation();
  const { f } = useWithLang();
  const { data } = useSWR<ApiResponse<RundownEvent[]>>(
    f("/public/allpastevents")
  );

  return (
    <Layout selected="/past_event" className="pt-24 flex h-full pb-12">
      <Helmet title={t("Last Event")} />
      <div className="flex flex-col flex-wrap container mx-auto font-sans">
        <h1 className="font-sans font-bold text-4xl text-red-600 w-full">
          {t("Past Event")}
        </h1>
        <div className="flex flex-wrap py-10 gap-6 justify-center lg:justify-start">
          {data?.data?.length === 0 ? (
            <>
              {[...Array(3)].map((_, i) => (
                <div
                  className="bg-gray-300 animate-pulse h-80 w-80 rounded"
                  key={i}
                />
              ))}
            </>
          ) : (
            <>
              {data?.data.map((item, i) => (
                <Link to={`/past_event/${splitAndJoin(item.title)}`} key={i}>
                  <div
                    className="max-w-xs min-h-full rounded overflow-hidden shadow-lg group cursor-pointer transform transition duration-500 hover:scale-105">
                    <img
                      className="w-full"
                      src="/images/ibd/background_ibd.jpg"
                      alt="Sunset in the mountains"
                    />
                    <div className="px-6 py-4">
                      <div className="font-bold text-xl mb-2 group-hover:text-red-600">
                        {item.title}
                      </div>
                      <p className="text-gray-700 text-base  group-hover:text-red-600">
                        {item.description}
                      </p>
                    </div>
                    <div className="px-6 pt-4 pb-2">
                      <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                        {item.date}
                      </span>
                    </div>
                  </div>
                </Link>
              ))}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default PastEventPage;
