const splitName = (name: string) => {
  let n = name;
  const result = name.split(" ");

  if (result[0]) {
    n = result[0];
  } else {
    n = name;
  }

  return n;
};

export default splitName;

export function splitAndJoin(title: string) {
  const str = title.split(" ").join("-");
  return str;
} 