import clsx from "clsx";
import { Field, FieldAttributes } from "formik";
import React from "react";
interface Props extends FieldAttributes<any> {}

const  InputField = (props: Props) => {
  return (
    <Field
      {...props}
      className={clsx(
        "form-input w-full px-3 py-2",
        "rounded-md border-gray-300",
        "focus:border-red-600 focus:ring-red-300 focus:ring-1"
      )}
    />
  );
};

export default InputField;
