import { Link, RouteComponentProps, useNavigate } from "@reach/router";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Components/Button";
import FormLoading from "../Components/FormLoading";
import Field from "../Components/InputField";
import Layout from "../Components/Layout";
import { RootState } from "../stores";
import { setLogin } from "../stores/features/user";
import Api from "../utils/api";

interface Props extends RouteComponentProps {}

const LoginPage = (props: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLogin } = useSelector((s: RootState) => s.user);

  const onSubmit = async (values, { setErrors }) => {
    try {
      const { data } = await Api.post("/login", values);
      dispatch(setLogin(data));
      toast.success(
        <>
          {t("Hello")}, <strong>{data.user.name}</strong>
        </>
      );
      navigate("/");
    } catch (e) {
      toast.error(e.response?.data?.message || t("Wrong email / password"));
    }
  };

  useEffect(() => {
    if (isLogin) {
      navigate("/");
    }
  }, [isLogin, navigate]);

  return (
    <Layout selected="/login" className="h-full flex">
      <Helmet title={t("Login")} />
      <div className="pt-28 container flex items-center justify-center flex-1 mx-auto font-sans">
        <div className="flex flex-col md:flex-row md:divide-x-2 w-full md:w-auto">
          <div className="md:pr-8 hidden md:block">
            <img
              src={process.env.PUBLIC_URL + "/images/login-illustration.jpg"}
              className="-mb-8 -mt-8"
              alt="login"
            />
          </div>
          <div className="md:pl-8 pb-8 md:pb-0">
            <div className="p-4 sm:w-96">
              <Formik initialValues={{}} onSubmit={onSubmit}>
                {({ isSubmitting }) => (
                  <Form>
                    <FormLoading isLoading={isSubmitting}>
                      <div className="mb-4">
                        <label className="block mb-2 font-bold">
                          {t("Email Address")}
                        </label>
                        <Field
                          className="form-input w-full"
                          name="email"
                          type="email"
                          placeholder={t("Enter a valid email address")}
                          required
                        />
                      </div>

                      <div className="mb-4">
                        <label className="block mb-2 font-bold">
                          {t("Password")}
                        </label>
                        <Field
                          className="form-input w-full"
                          name="password"
                          type="password"
                          placeholder={t("Enter password")}
                          required
                        />

                        <div className="text-right mt-2">
                          <Link
                            to="/forgot"
                            className="text-gray-800 hover:underline"
                          >
                            {t("Forgot Password?")}
                          </Link>
                        </div>
                      </div>

                      <div>
                        <Button
                          type="submit"
                          color="primary"
                          className="font-bold"
                        >
                          {t("Login")}
                        </Button>
                      </div>
                    </FormLoading>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="p-4">
              {t(`Don't have an account?`)}{" "}
              <a href="https://s.id/form-ibd" target="_blank" className="text-red-600 hover:underline" rel="noreferrer">
                {t("Register")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LoginPage;
