import { Menu, Transition } from "@headlessui/react";
import clsx from "clsx";
import React, { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { languages } from "../i18n";

interface Props {
  isTransparent?: boolean;
}

const LanguageButton = ({ isTransparent = false }: Props) => {
  const { i18n } = useTranslation();

  const currentLang = useMemo(() => {
    return languages.find((item) => item.id === i18n.language);
  }, [i18n.language]);

  return (
    <Menu>
      <Menu.Button>
        <button
          className={clsx(
            "block ml-6 -mt-1 md:ml-0 px-3 py-3 md:px-1 md:py-1 bg-black bg-opacity-10 rounded-md",
            { "text-gray-800": isTransparent, "text-black": !isTransparent }
          )}
        >
          {currentLang.id.toUpperCase()}
        </button>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="mt-2 absolute md:origin-top-right md:right-0 w-40 bg-white overflow-hidden rounded-md shadow-md">
          {languages.map((item, index) => (
            <Menu.Item key={index}>
              <button
                onClick={() => {
                  i18n.changeLanguage(item.id);
                }}
                className={clsx(
                  "px-3 py-2 hover:bg-gray-200 appearance-none w-full text-left flex items-center",
                  {
                    "font-bold": item.id === i18n.language,
                  }
                )}
              >
                <div>{item.icon}</div>
                <span className="flex-1 pl-3">{item.label}</span>
              </button>
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default LanguageButton;
