import { Link, RouteComponentProps, useParams } from "@reach/router";
import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import EventRundownPastEvent from "../Components/EventRundownPastEvent";
import { SpeakerItem } from "../Components/EventSpeakers";
import Layout from "../Components/Layout";
import { useWithLang } from "../i18n";
import ChevronRight from "../icons/ChevronRight";
import { ApiResponse, getBaseURL } from "../utils/api";
import { PastEvent } from "../utils/types";
interface Props extends RouteComponentProps {}

const PastEventSub = (props: Props) => {
  const { t } = useTranslation();
  const { f } = useWithLang();
  const params = useParams();
  const pastEvent = useSWR<ApiResponse<PastEvent>>(
    f("/public/pastevent?title=" + params.id)
  );

  return (
    <Layout selected="/past_event" className="pt-24 h-full pb-12">
      <Helmet title={t("Last Event")} />

      {/* breadcrumb */}
      <div className="bg-pandi py-10 px-6 lg:px-52 flex items-center text-sm text-white mb-16 text-center gap-3 font-sans">
        <div>
          <Link to="/past_event" className="hover:text-red-500">
            {t("Past Event")}
          </Link>
        </div>
        <ChevronRight className="h-4 w-4" />
        <div>{pastEvent.data?.data?.slider?.title ?? "-"}</div>
      </div>
      {/* title */}
      <h1 className="text-3xl md:text-4xl font-bold font-sans text-red-600 mb-5 md:mb-6 container mx-auto">
        {pastEvent.data?.data?.slider?.title ?? "-"}
      </h1>
      {/* jumbotron */}
      <div
        className="group relative sm:container flex justify-center mx-auto overflow-hidden mb-16 bg-center bg-cover bg-no-repeat p-12 text-center shadow-lg transform transition duration-500"
        style={{
          backgroundImage: `url(/images/ibd/background_ibd.jpg)`,
          height: 500,
        }}>
        <div
          className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
          style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}>
          <div className="flex h-full items-center justify-center">
            <div className="">
              <h2 className="mb-4 text-4xl font-semibold group-hover:text-red-600 transform transition duration-500">
                {pastEvent.data?.data?.slider?.title ?? "-"}
              </h2>
              <h4 className="mb-6 text-xl font-semibold group-hover:text-red-600 transform transition duration-500">
                {pastEvent.data?.data?.slider?.description ?? "-"}
              </h4>
              <h4 className="mb-6 text-xl font-semibold group-hover:text-red-600 transform transition duration-500">
                {pastEvent.data?.data?.slider?.date ?? "-"}
              </h4>
            </div>
          </div>
        </div>
      </div>
      {/* text */}
      <div className="container mx-auto font-sans mb-16">
        <h1 className="text-3xl md:text-4xl font-bold font-sans text-red-600 mb-5 md:mb-6">
          {t("ABOUT")}
        </h1>
        <div className="leading-loose text-lg text-justify">
          {pastEvent?.data === null ? (
            <>
              {[...new Array(3)].map((_, index) => (
                <div className="mb-8">
                  <div className="bg-gray-300 mb-4 animate-pulse h-4 rounded-md w-11/12" />
                  <div className="bg-gray-300 mb-4 animate-pulse h-4 rounded-md w-10/12" />
                  <div className="bg-gray-300 mb-4 animate-pulse h-4 rounded-md w-11/12" />
                  <div className="bg-gray-300 mb-4 animate-pulse h-4 rounded-md w-9/12" />
                </div>
              ))}
            </>
          ) : (
            <div>
              {pastEvent.data?.data?.setting?.value
                ?.split("\n")
                .map((content, index) => (
                  <p className="mb-3" key={index}>
                    {content}
                  </p>
                ))}
            </div>
          )}
        </div>
      </div>
      {/* roundown */}
      <div className="flex flex-col flex-wrap container mx-auto font-sans mb-16">
        <h1 className="font-sans font-bold text-4xl text-red-600 w-full mb-10">
          {t("Schedule")}
        </h1>
        <EventRundownPastEvent datas={pastEvent.data?.data.event} />
      </div>
      {/* speakers */}
      <div className="bg-gray-200 mb-16">
        <div className="flex flex-col flex-wrap mx-auto font-sans container py-10">
          <h1 className="font-sans font-bold text-4xl text-red-600 w-full">
            {t("Speakers")}
          </h1>
          <div className="w-full">
            <div className="mt-10 flex flex-wrap -m-4">
              {pastEvent.data?.data?.speakers?.map((item, index) => {
                return (
                  <SpeakerItem
                    key={index}
                    item={item}
                    className="w-6/12 md:w-3/12 p-4"
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/* sponsor */}
      <div className="flex flex-col flex-wrap container mx-auto font-sans">
        <h1 className="font-sans font-bold text-4xl text-red-600 w-full">
          {t("Partners")}
        </h1>

        <div className="mt-10 w-full">
          <div className="flex flex-wrap -m-2 mb-8">
            {pastEvent.data?.data?.sponsors?.map((item, index) => (
              <div className="w-6/12 md:w-3/12 p-2" key={index}>
                <div className="bg-gray-300 h-16 md:h-20 rounded-xl flex justify-center items-center p-1">
                  <img
                    src={getBaseURL(item.image)}
                    className="max-h-full"
                    alt={item.name}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PastEventSub;
