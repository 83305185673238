const dokumentasi = [
  {
    label: "Picture1",
    url: "/images/ibd/Picture2.png",
  },
  {
    label: "Picture2",
    url: "/images/ibd/Picture1.png",
  },
  {
    label: "Picture3",
    url: "/images/ibd/Picture3.png",
  },
  {
    label: "Picture4",
    url: "/images/ibd/Picture4.png",
  },
  {
    label: "Picture5",
    url: "/images/ibd/Picture5.png",
  },
  {
    label: "Picture6",
    url: "/images/ibd/Picture6.png",
  },
];

export default dokumentasi;
