import { Field, Form, Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import * as Yup from "yup";
import ErrorMessage from "../Components/ErrorMessage";
import { useWithLang } from "../i18n";
import CloseIcon from "../icons/CloseIcon";
import Api, { ApiResponse, parseErrorValidation } from "../utils/api";
import Button from "./Button";
import FormLoading from "./FormLoading";
import InputField from "./InputField";
import { year } from "./Layout";
import Modal, { useModal } from "./Modal";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const sponsorsInputValidation = Yup.object().shape({
  fullname: Yup.string().required("Full Name is a required field").min(3),
  email: Yup.string()
    .email("Format email not valid")
    .required("Email is a required field")
    .min(3),
  phone: Yup.string().matches(phoneRegExp, "Phone Number is not valid"),
  company_name: Yup.string()
    .required("Company Name is a required field")
    .min(3),
  sponsor_type: Yup.string().required("Sponsor Type is a required field"),
});

const sponsorsInputInitialValues = {
  fullname: "",
  email: "",
  phone: "",
  company_name: "",
  sponsor_type: "",
};

function BannerSponsorship() {
  const { t } = useTranslation();
  const { f } = useWithLang();

  const modalSponsors = useModal();

  const modalClick = () => {
    modalSponsors.setIsOpen(true);
  };

  const onSubmitInputSponsor = async (values: any, { setErrors }) => {
    console.log(values);

    try {
      await Api.post("/public/sponsorship", values);
      toast.success(t("Your Request Send sucessfully!"));
      modalSponsors.setIsOpen(false);
    } catch (e) {
      if (e.response?.data?.errors) {
        setErrors(parseErrorValidation(e.response?.data?.errors));
      } else {
        toast.error(
          e.response?.data?.message || "Something went wrong!, please try again"
        );
      }
    }
  };
  return (
    <div className="bg-pandi p-14 text-white justify-center justify-items-center space-y-5 mb-10 text-center">
      <div className="text-4xl">
        {t("text-sponsor-1")} {year}
      </div>
      <div>{t("text-sponsor-2")}</div>
      <div>
        <Button
          className="mb-4 md:text-base font-sans"
          color="primary"
          onClick={modalClick}>
          {t("button-sponsor")}
        </Button>
        <Modal
          {...modalSponsors}
          className="bg-white p-4 rounded-lg max-w-screen-lg">
          <div className="flex justify-between py-5">
            <div className="">
              <div className="font-bold text-xl">
                {t("Sponsorship Interest Form")}
              </div>
              <div>{t("Request our sponsorship & speaking opportunities")}</div>
            </div>
            <button
              className="hover:opacity-50 p-1"
              onClick={() => modalSponsors.setIsOpen(false)}>
              <CloseIcon />
            </button>
          </div>
          <div>
            <Formik
              initialValues={sponsorsInputInitialValues}
              onSubmit={onSubmitInputSponsor}
              validationSchema={sponsorsInputValidation}>
              {({ isSubmitting, values, handleChange }) => (
                <Form>
                  <FormLoading isLoading={isSubmitting}>
                    <div className="flex flex-wrap -mx-4">
                      <div className="p-4 w-full">
                        <label className="block mb-2 font-bold">
                          {t("Full Name")}
                        </label>
                        <InputField
                          className="form-input w-full"
                          name="fullname"
                          type="text"
                          placeholder={t("Full Name")}
                        />
                        <ErrorMessage name="fullname" />
                      </div>
                      <div className="p-4 w-full">
                        <label className="block mb-2 font-bold">
                          {t("Email")}
                        </label>
                        <InputField
                          className="form-input w-full"
                          name="email"
                          placeholder={t("Email")}
                        />
                        <ErrorMessage name="email" />
                      </div>
                      <div className="p-4 w-full">
                        <label className="block mb-2 font-bold">
                          {t("Company Name")}
                        </label>
                        <InputField
                          className="form-input w-full"
                          name="company_name"
                          placeholder={t("Company Name")}
                        />
                        <ErrorMessage name="company_name" />
                      </div>
                      <div className="p-4 w-full">
                        <label className="block mb-2 font-bold">
                          {t("Phone Number")}
                        </label>
                        <InputField
                          className="form-input w-full"
                          name="phone"
                          type="text"
                          placeholder={t("Phone Number")}
                        />
                        <ErrorMessage name="phone" />
                      </div>
                      <div className="p-4 w-full">
                        <label className="block mb-2 font-bold">
                          {t("Sponsor Type")}
                        </label>
                        <Field
                          as="select"
                          className="form-input w-full px-3 py-2 rounded-md border-gray-300"
                          name="sponsor_type"
                          value={values.sponsor_type}
                          onChange={handleChange}>
                          <option label={t("Select Type")}>""</option>
                          <option value="1" label="Gold">
                            Gold
                          </option>
                          <option value="2" label="Silver">
                            Silver
                          </option>
                          <option value="3" label="Bronze">
                            Bronze
                          </option>
                        </Field>
                        <ErrorMessage name="sponsor_type" />
                      </div>
                      <h3 className="p-4 w-full">
                        {t("Enter as many details as possible about your vision and will make it happen!")}
                      </h3>
                      <div className="p-4 w-full flex justify-end gap-2">
                        <button
                          className="bg-gray-300 hover:opacity-80 px-3 rounded-md"
                          type="button"
                          onClick={() => {
                            modalSponsors.setIsOpen(false);
                          }}>
                          Cancel
                        </button>
                        <Button
                          type="submit"
                          color="primary"
                          className="font-bold">
                          {t("Save Changes")}
                        </Button>
                      </div>
                    </div>
                  </FormLoading>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default BannerSponsorship;
