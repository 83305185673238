import React from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";

const Figures = () => {
  const { t } = useTranslation();
  return (
    <div className="py-14 text-center bg-black">
      <div className="">
        <h1 className="font-bold text-2xl lg:text-4xl font-sans text-red-500">
          {t("figures.title")}
        </h1>
        <div className="md:flex items-center justify-center space-x-32 text-red-500">
          <div className="p-10">
            <div className="text-5xl">
              <CountUp end={1000} duration={3} />+
            </div>
            <div className="">{t("figures.total_audience_regitered")}</div>
          </div>
          <div className="">
            <div className="text-5xl">
              <CountUp end={500} duration={3} />+
            </div>
            <div className="">{t("figures.audience_day_1")}</div>
          </div>
          <div className="">
            <div className="text-5xl">
              <CountUp end={300} duration={3} />+
            </div>
            <div className="">{t("figures.audience_day_2")}</div>
          </div>
          <div className="">
            <div className="text-5xl">
              <CountUp end={800} duration={3} />+
            </div>
            <div className="">{t("figures.total_audience_present")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Figures;
