import { Link, RouteComponentProps } from "@reach/router";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import BigSlides from "../Components/BigSlides";
// import Button from "../Components/Button";
// import EventPlansSection from "../Components/EventPlans";
// import EventRundown from "../Components/EventRundown";
// import EventSpeakers from "../Components/EventSpeakers";
// import EventSponsors from "../Components/EventSponsors";
import Layout from "../Components/Layout";
// import Testimonials from "../Components/Testimonials";
// import WhatsAbout from "../Components/WhatsAbout";
import { useSelector } from "react-redux";
import { useWithLang } from "../i18n";
// import Printicon from "../icons/PrintIcon";
import { RootState } from "../stores";
import { ApiResponse } from "../utils/api";
import { SettingProp } from "../utils/types";
// import InfoLomba from "../Components/InfoLomba";
// import Expo from "../Components/Expo";
// import BigSlidesIBD from "../Components/IDB/BigSlidesIBD";
// import Dokumentasi from "../Components/IDB/Dokumentasi";
// import Figures from "../Components/IDB/Figures";
// import InfoIBD from "../Components/IDB/InfoIBD";
// import Video from "../Components/IDB/Video";

interface Props extends RouteComponentProps {}

const IndexPage = (props: Props) => {
  const sliderRef = useRef();
  const { t } = useTranslation();
  const { f } = useWithLang();
  const { isLogin } = useSelector((state: RootState) => state.user);
  const url_register = isLogin ? "/register-event" : "https://s.id/form-ibd";

  const { data: bookSeat } = useSWR<ApiResponse<SettingProp>>(
    f("/public/pages/book-seat"),
    {
      initialData: null,
    }
  );

  return (
    <Layout transparent lightAfter={sliderRef}>
      <section className="mb-8">
        <BigSlides compRef={sliderRef} />
      </section>
      {/* <section className="my-10 md:my-20 container mx-auto">
        <WhatsAbout />`
      </section> */}
      {/* <section className="my-5 md:my-10 container mx-auto">
        <Video />
      </section> */}
      {/* <section className="my-5 md:my-10 container mx-auto">
        <InfoLomba />
        <InfoIBD />
      </section> */}
      {/* <section className="my-5 md:my-10 container mx-auto">
        <Expo />
      </section> */}
      {/* <section>
        <Figures />
      </section> */}
      {/* <div className="bg-gray-200 pt-4 -mt-10">
        <section className="pb-10">
          <EventSpeakers />
        </section>
        <section className="my-10 container mx-auto hidden">
          <div className="flex items-center flex-col md:flex-row">
            <div className="flex-1 flex w-full md:w-auto items-center mb-6 md:mb-0">
              <div className="mr-3">
                <img
                  src="/images/pandi.png"
                  className="w-14 md:w-auto"
                  alt="pandi id logo"
                />
              </div>

              <h2 className="text-xl md:text-3xl font-semibold font-sans">
                {t("RUNDOWN EVENT")}
              </h2>
            </div>
            <div className="w-full md:w-auto flex justify-end">
              <Button
                size="md"
                className="flex items-center font-sans md:text-xl font-semibold bg-white"
                color="secondary"
                onClick={() => {
                  window.open(
                    process.env.REACT_APP_BASE_URL + "/public/events/download",
                    "_blank"
                  );
                }}
                outline>
                <Printicon className="mr-2 w-6 h-6" />
                {t("Download Schedule")}
              </Button>
            </div>
          </div>
        </section>
        <div className="container mx-auto mb-8">
          {isLogin && (
            <Link to="/register-event">
              <Button color="primary" className="font-sans">
                {t("REGISTER EVENT")}
              </Button>
            </Link>
          )}
        </div>

        <section className="mb-8 container mx-auto">
          <EventRundown />
        </section>

        <section className="hidden">
          <EventPlansSection />
        </section>

        <section className="">
          <EventSponsors />
        </section>

        <section className="hidden">
          <Testimonials />
        </section>

        <section className="border border-t">
          <Dokumentasi />
        </section>

        <section className="mx-auto hidden">
          <div
            className="flex flex-col items-center py-16 px-8 text-center text-white"
            style={{
              background: "#1A1438",
            }}>
            <h1 className="text-3xl font-bold">{t("BOOK YOUR SEAT")}</h1>
            <p className="mt-4" style={{ maxWidth: 600 }}>
              {bookSeat?.data?.value}
            </p>
            <div className="mt-4">
              {isLogin ? (
                <Link to={url_register}>
                  {" "}
                  <Button
                    className="mx-2 mb-4 md:text-2xl font-sans"
                    color="primary">
                    {t("REGISTER")}
                  </Button>
                </Link>
              ) : (
                <a href={url_register}>
                  <Button
                    className="mx-2 mb-4 md:text-2xl font-sans"
                    color="primary">
                    {t("REGISTER")}
                  </Button>
                </a>
              )}
            </div>
          </div>
        </section>
      </div> */}
    </Layout>
  );
};

export default IndexPage;
