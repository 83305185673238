import React from "react";

const Video = () => {
  return (
    <div className="pb-10 text-center">
      <div className="mb-5 md:mb-10">
        <h1 className="font-bold text-2xl lg:text-4xl font-sans mb-8 md:mb-14">
          Throwback to Indonesia Berdaulat Digital Indonesia 2024
        </h1>
        <div className="flex items-center justify-center">
          <iframe
            width="100%"
            height="400"
            className="transition w-3/4 md:w-full md:h-[315px]"
            src={`https://www.youtube.com/embed/8LI3oIrQGpA?&loop=1&playlist=8LI3oIrQGpA`}
            title="Teaser IBD"
            frameBorder="0"
            style={{
              maxHeight: "600px",
              overflow: "hidden",
              borderRadius: "10px",
              padding: "0 5px",
            }}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; web-share"
            allowFullScreen></iframe>

          {/* <div className="text-center md:text-left md:w-8/12 md:px-6">
            <h1 className="font-bold text-2xl lg:text-4xl font-sans mb-3">
              Watch the teaser Indonesia Berdaulat Digital Indonesia
            </h1>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Video;
